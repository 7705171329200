import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un petit capell groc ocraci de 2 a 3 cm de diàmetre, acampanat amb el marge girat cap amunt, higròfan i amb el marge una mica estriat. Les làmines són adnades, de color semblant al del capell o un poc més clares. El peu és llarg i prim, amb zones blanquinoses i les espores ocràcies en massa, són el·líptiques, una mica berrugoses, de 9-12 x 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      